
import { ITranslateValue } from '../types';
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Admin } from '@/modules/admin'

@Component
export default class TranslateDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly translate: ITranslateValue | undefined;

  admin = $app.module(Admin);

  tab = 0;
  item: ITranslateValue | null = null;
  reseting = false;
  pwd = '';

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.translate);
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    this.$emit('store', this.item)
  }

  remove() {
    this.$emit('remove', this.item)
  }
}
