import { createSearch, System } from '@/modules/system';
import $app from '@/plugins/modules';
import store from '@/plugins/store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IClinic, ICompany, ICompanyService, IEntityOperation, IPagination, IRoleAccess, IUser, IUserRole } from '../system/types';
import { ICompanySearch, IRolesSearch, ITranslateItem, ITranslateValue, ITransSearch, IUsersSearch } from './types';

const $system = $app.module(System);

@Module({ dynamic: true, store, name: 'admin' })
export default class AdminStore extends VuexModule {
  private usersSearch: IUsersSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        search: ''
      }
    )
  }

  private rolesSearch: IRolesSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        search: ''
      }
    )
  }

  private transSearch: ITransSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        search: ''
      }
    )
  }

  private companySearch: ICompanySearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        search: ''
      }
    )
  }

  // ---------------------------------------------------------------------- GETTERS

  get UsersSearch(): IUsersSearch {
    return this.usersSearch
  }

  get RolesSearch(): IRolesSearch {
    return this.rolesSearch
  }

  get TransSearch(): ITransSearch {
    return this.transSearch
  }

  get CompanySearch(): ICompanySearch {
    return this.companySearch
  }

  // ---------------------------------------------------------------------- MUTATIONS

  @Mutation
  setUsersSearch(value: IUsersSearch) {
    this.usersSearch = value;
  }

  @Mutation
  changeUser(oper: IEntityOperation<IUser>) {
    let index = -1;
    for (let i = 0; i < this.usersSearch.items!.length; i++) {
      if (this.usersSearch.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.usersSearch.items = [oper.value].concat(this.usersSearch.items || []);
    } else if (oper.type === 'store') {
      this.usersSearch.items.splice(index, 1, oper.value);
    } else {
      this.usersSearch.items.splice(index, 1);
    }
  }

  // -------------------------------- ROLES

  @Mutation
  setRolesSearch(value: IRolesSearch) {
    this.rolesSearch = value;
  }

  @Mutation
  changeRole(oper: IEntityOperation<IUserRole>) {
    let index = -1;
    for (let i = 0; i < this.rolesSearch.items!.length; i++) {
      if (this.rolesSearch.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.rolesSearch.items = [oper.value].concat(this.rolesSearch.items || []);
    } else if (oper.type === 'store') {
      this.rolesSearch.items.splice(index, 1, oper.value);
    } else {
      this.rolesSearch.items.splice(index, 1);
    }
  }

  // -------------------------------- TRANSLATES

  @Mutation
  setTransSearch(value: ITransSearch) {
    this.transSearch = value;
  }

  // -------------------------------- COMPANIES

  @Mutation
  setCompanySearch(value: ICompanySearch) {
    this.companySearch = value;
  }

  @Mutation
  changeCompany(oper: IEntityOperation<ICompany>) {
    let index = -1;
    for (let i = 0; i < this.companySearch.items!.length; i++) {
      if (this.companySearch.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.companySearch.items = [oper.value].concat(this.companySearch.items || []);
    } else if (oper.type === 'store') {
      this.companySearch.items.splice(index, 1, oper.value);
    } else {
      this.companySearch.items.splice(index, 1);
    }
  }

  // ---------------------------------------------------------------------- ACTIONS

  @Action({ rawError: true })
  async resetUserPassword(value: IUser): Promise<string> {
    return await $app.post('/api/admin/users/' + value.id + '/password', {})
  }

  @Action({ rawError: true })
  async addUserRole(value: IUserRole) {
    await $app.post('/api/admin/users/' + value.userId + '/roles/' + value.id, {})
  }

  @Action({ rawError: true })
  async addUserClinic(value: IClinic) {
    await $app.post('/api/admin/users/' + value.userId + '/clinics/' + value.id, {})
  }

  @Action({ rawError: true })
  async delUserRole(value: IUserRole) {
    await $app.delete('/api/admin/users/' + value.userId + '/roles/' + value.id)
  }

  @Action({ rawError: true })
  async delUserClinic(value: IClinic) {
    await $app.delete('/api/admin/users/' + value.userId + '/clinics/' + value.id)
  }

  @Action({ rawError: true })
  async loadUserData(value: IUser): Promise<IUser> {
    return await $app.get('/api/admin/users/' + value.id);
  }

  @Action({ rawError: true })
  async findUsers(pagination: IPagination) {
    this.setUsersSearch(
      await $system.$module.find('/api/admin/users/search', this.usersSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeUser(value: IUser): Promise<IUser> {
    value = await $app.post('/api/admin/users', value);
    this.changeUser({ type: 'store', value });
    return value;
  }

  @Action({ rawError: true })
  async removeUser(value: IUser) {
    await $app.delete('/api/admin/users/' + value.id);
    this.changeUser({ type: 'remove', value });
  }

  // -------------------------------- ROLES

  @Action({ rawError: true })
  async addRoleAccess(value: IRoleAccess) {
    await $app.post('/api/admin/roles/' + value.roleId + '/access/' + value.accessCode, {})
  }

  @Action({ rawError: true })
  async delRoleAccess(value: IRoleAccess) {
    await $app.delete('/api/admin/roles/' + value.roleId + '/access/' + value.accessCode)
  }

  @Action({ rawError: true })
  async loadRole(value: IUserRole): Promise<IUserRole> {
    return await $app.get('/api/admin/roles/' + value.id);
  }

  @Action({ rawError: true })
  async findRoles(pagination: IPagination) {
    this.setRolesSearch(
      await $system.$module.find('/api/admin/roles/search', this.rolesSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeRole(value: IUserRole): Promise<IUserRole> {
    value = await $app.post('/api/admin/roles', value);
    this.changeRole({ type: 'store', value });
    return value;
  }

  @Action({ rawError: true })
  async removeRole(value: IUserRole) {
    await $app.delete('/api/admin/roles/' + value.id);
    this.changeRole({ type: 'remove', value });
  }

  // -------------------------------- TRANSLATES

  @Action({ rawError: true })
  async loadTranslate(value: ITranslateValue): Promise<ITranslateItem> {
    return await $app.get('/api/admin/translates/' + value.key);
  }

  @Action({ rawError: true })
  async findTranslates(pagination: IPagination) {
    this.setTransSearch(
      await $system.$module.find('/api/admin/translates/search', this.transSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeTranslates(value: ITranslateItem): Promise<ITranslateItem> {
    value = await $app.post('/api/admin/translates', value);
    return value;
  }

  @Action({ rawError: true })
  async removeTranslate(value: ITranslateItem) {
    await $app.delete('/api/admin/translates/' + value.key);
  }

  // -------------------------------- COMPANIES

  @Action({ rawError: true })
  async storeCompanyClinic(value: IClinic) {
    await $app.post('/api/admin/companies/' + value.companyId + '/clinics', value)
  }

  @Action({ rawError: true })
  async delCompanyClinic(value: IClinic) {
    await $app.delete('/api/admin/companies/' + value.companyId + '/clinics/' + value.id)
  }

  @Action({ rawError: true })
  async storeCompanyService(value: ICompanyService) {
    await $app.post('/api/admin/companies/' + value.companyId + '/services', value)
  }

  @Action({ rawError: true })
  async delCompanyService(value: ICompanyService) {
    await $app.delete('/api/admin/companies/' + value.companyId + '/services/' + value.id)
  }

  @Action({ rawError: true })
  async findCompany(pagination: IPagination) {
    this.setCompanySearch(
      await $system.$module.find('/api/admin/companies/search', this.companySearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeCompany(value: ICompany): Promise<ICompany> {
    value = await $app.post('/api/admin/companies', value);
    this.changeCompany({ type: 'store', value });
    return value;
  }

  @Action({ rawError: true })
  async removeCompany(value: ICompany) {
    await $app.delete('/api/admin/companies/' + value.id);
    this.changeCompany({ type: 'remove', value });
  }
}
