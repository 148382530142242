import { AppModule, VuexModuleConstructor } from '@/common/types'
import AdminStore from './AdminStore'
import AdminModule from './AdminModule'

export class Admin extends AppModule<AdminStore, AdminModule> {
  public get name(): string {
    return 'admin'
  }

  protected get storeClass(): VuexModuleConstructor<AdminStore> {
    return AdminStore
  }
}

export default AdminModule
